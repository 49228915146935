<script>
import toMonth from "@/helpers/toMonth";
import { mapGetters } from 'vuex';

export default {
  components: {
    GModal: () => import("@/components/g-modal.vue"),
  },
  props: {
    data: Object,
    editMode: Boolean,
    opened: Boolean,
  },
  data() {
    return {
      atividades: [],
      errorMessage: "",
      innerData: {},
      innerOpened: false,
      projetosEnquadrados: [],
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters(['anoBase', 'clientId', 'servico']),
    dateLimits() {
      const max = `${this.anoBase}-12-31`;
      const min = `${this.anoBase}-01-01`;
      const initial = `${this.anoBase}-01`;
      return { initial, max, min };
    },
    modalData: {
      get() {
        return this.data ?? this.innerData;
      },
      set(value) {
        this.innerData = value;
        this.$emit("update:data", value);
      },
    },
    modalOpened: {
      get() {
        return this.opened ?? this.innerOpened;
      },
      set(value) {
        this.innerOpened = value;
        this.$emit("update:opened", value);
      },
    },
    prependInnerIcon() {
      return this.modalData.subtrair
        ? "mdi-clock-minus-outline"
        : "mdi-clock-plus-outline";
    },
  },
  watch: {
    clientId() {
      this.atividades = [];
      this.projetosEnquadrados = [];
    },
    "modalData.projetoId": function() {
      this.modalData.atividadeId = null;
      this.getAtividades();
    },
    modalOpened() {
      this.getProjetos();
      this.errorMessage = "";
    },
  },
  methods: {
    changeHandler: function (value) {      
      if (!value) {
        return;
      }

      if (this.modalData.competencia != value) {
        this.modalData.competencia = value;
      }
    },
    async closeHandler(shouldAdjustment) {
      if (!shouldAdjustment) {
        return;
      }

      try {
        const { competencia, funcionarioId, horas, projetoId, subtrair } =
          this.modalData;
        let parsedHoras = Math.abs(horas);

        if (subtrair) {
          parsedHoras *= -1;
        }

        const body = {
          competencia,
          funcionarioId,
          horas: parsedHoras,
          projetoId,
          trabalho: this.servico,
        };
        const resource = this.apiResource(
          `/v1/timesheet/${this.clientId}/ajusteHoras`
        );
        await resource.save(body);
        this.modalOpened = false;
        this.$emit('reload');
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
      }
    },
    async getAtividades() {
      if (this.atividades.length > 0 || !this.modalData.projetoId) {
        return;
      }

      try {
        const { competencia, projetoId, funcionarioId } = this.modalData;
        const resource = this.apiResource(
          `/v1/timesheet/${this.clientId}/atividades/selecao?projetoId=${projetoId}&funcionarioId=${funcionarioId}&competencia=${competencia}`
        );
        const response = await resource.get();

        if (Array.isArray(response)) {
          this.atividades = response;
        }
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
      }
    },
    async getProjetos() {
      if (this.projetosEnquadrados.length > 0 || this.editMode) {
        return;
      }

      try {
        const resource = this.apiResource(
          `/v1/projetos/${this.clientId}/selecao?ano=${this.anoBase}`
        );
        const response = await resource.get();

        if (Array.isArray(response.projetos)) {
          this.projetosEnquadrados = response.projetos;
        }
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
      }
    },
    keypressHandler(event) {
      // Impede a digitação de '-'
      if (["Minus", "NumpadSubtract"].includes(event.code)) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    toMonth,
  },
};
</script>

<template>
  <GModal
    v-model="modalOpened"
    appendTitleIcon="mdi-clock-alert-outline"
    title="Ajustes de horas"
    @close="closeHandler"
  >
    <div>Colaborador <b>{{ modalData.funcionarioNome }} </b></div>
    <div v-if="editMode">Competência <b>{{ toMonth(modalData.competencia) }}</b></div>
    <v-menu
      v-else
      v-model="showMenu"
      closeOnClick
      :closeonContentClick="false"
      transition="scroll-y-transition"
      offset-y
      right
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-bind="attrs"
          v-on="on"
          dense
          outlined
          readonly
          hide-details
          class="mt-2"
          placeholder="Selecione uma competência"
          prepend-inner-icon="mdi-calendar"
          label="Competência"
          :value="toMonth(modalData.competencia)"
          @click:prepend.stop="on.click"
        ></v-text-field>
      </template>
      <v-date-picker
        :max="dateLimits.max"
        :min="dateLimits.min"
        no-title
        type="month"
        :value="modalData.competencia || dateLimits.initial"
        @click:month="showMenu = false"
        @change="changeHandler"
        @input="modalData.competencia = $event"
      ></v-date-picker>
    </v-menu>

    <div v-if="editMode">Projeto <b>{{ modalData.titulo }} </b></div>
    <v-autocomplete
      v-else
      v-model="modalData.projetoId"
      class="mt-3"
      dense
      hide-details
      itemText="titulo"
      itemValue="id"
      :items="projetosEnquadrados"
      label="Projeto"
      outlined
      placeholder="Selecione um projeto"
      prepend-inner-icon="mdi-clipboard-text"
      :menu-props="{ offset: true, top: true }"
    ></v-autocomplete>

    <v-radio-group v-model="modalData.subtrair" label="Ação:" row>
      <v-radio label="Somar horas" :value="false"></v-radio>
      <v-radio label="Subtrair horas" :value="true" :disabled="!editMode"></v-radio>
    </v-radio-group>
    <v-text-field
      v-model="modalData.horas"
      dense
      label="Quantidade"
      :min="0"
      outlined
      placeholder="0,00"
      :prependInnerIcon="prependInnerIcon"
      type="number"
      @keypress="keypressHandler"
    ></v-text-field>
    <v-autocomplete
      v-if="atividades.length"
      v-model="modalData.atividadeId"
      dense
      label="Atividade"
      itemText="atividade"
      itemValue="id"
      :items="atividades"
      outlined
      placeholder="Selecione uma atividade"
      prependInnerIcon="mdi-format-list-bulleted-type"
      :menu-props="{ offset: true, top: true }"
    ></v-autocomplete>

    <v-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>

    <template v-slot:buttons="{ close }">
      <v-spacer />
      <v-btn
        class="px-3"
        color="secondary"
        dark
        depressed
        @click="close(false)"
      >
        Cancelar
      </v-btn>
      <v-btn class="px-3" color="primary" dark depressed @click="close(true)">
        Ajustar
      </v-btn>
    </template>
  </GModal>
</template>

<style lang="scss" scoped>
$dark-background: #81b4ea;
$light-error: #fb5252;

.add-hours {
  color: $dark-background;
}

.subtract-hours {
  color: $light-error;
}
</style>
